import React from 'react';
import './Pricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
function Pricing() {
      const navigate = useNavigate();
      const location = useLocation();
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        } else {
          if (id === "home") {
           
            navigate("/");
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            navigate(`/${id}`);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      };
    return (
        <div className="pricing" id="pricing">
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Responsive Pricing Section [Pure CSS]</title>
            {/* Google Fonts Link */}
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"
                rel="stylesheet"
            />
            <section className='main-p'>
                <div className="container-p">
                    <div className="section-intro">
                        <h2 className="pricing-title" >SIMPLE PRICING FOR EVERYONE</h2>
                        <div className="section-style" />
                    </div>
                    <div className="pricing-table__wrapper">
                        <div className="pricing-card-ps">
                            <div className="row">
                                <div className="card-p">
                                    <div className="card-p-pricing__header" >
                                        <h4 className="pricing-plan-title">Free Plan</h4>
                                        <h1 className="card-p-pricing__price">
                                            <span className='sign'>$</span>0.00
                                        </h1>
                                    </div>
                                    <ul className="card-p-pricing__list">
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Create unlimited presentations</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Customize with colors, fonts, designs, and AI-generated images.</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Easily share your presentations</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">High Quality Editable Format in pptx</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className='bold-list'>0 Presentation downloads per month</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Ideal for personal, office, and educational use</span>
                                        </li>
                                    </ul>
                                    <div className="card-p-pricing__footer">
                                        <button className="button-p" onClick={() => scrollToSection('home')}>Get Started</button>
                                    </div>
                                </div>
                                <div className="card-p">
                                    <div className="card-p-pricing__header" >
                                        <h4 className="pricing-plan-title">Pay-Per-Download</h4>
                                        <h1 className="card-p-pricing__price">
                                            <span className='sign' style={{ marginLeft: '35px' }}>$</span>1.99
                                            <span className='payment-text'>/download</span>
                                        </h1>
                                    </div>
                                    <ul className="card-p-pricing__list">
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Create unlimited presentations</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Customize with colors, fonts, designs, and AI-generated images.</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Easily share your presentations</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">High Quality Editable Format in pptx</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className='bold-list'>1 presentation download per payment</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Ideal for personal, office, and educational use</span>
                                        </li>
                                    </ul>
                                    <div className="card-p-pricing__footer">
                                        <button className="button-p" onClick={() => scrollToSection('home')}>Get Started</button>
                                    </div>
                                </div>
                                <div className="card-p">
                                    <div className="card-p-pricing__header" >
                                        <h4 className="pricing-plan-title">Pro Plan</h4>
                                        <h1 className="card-p-pricing__price">
                                            <span className='sign' style={{ marginLeft: '35px' }}>$</span>9.99
                                            <span className='payment-text'>/month</span>
                                        </h1>
                                    </div>
                                    <ul className="card-p-pricing__list">
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Create unlimited presentations</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Customize with colors, fonts, designs, and AI-generated images.</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Easily share your presentations</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">High Quality Editable Format in pptx</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className='bold-list'>20 presentation downloads per month</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} className="pricing-icon" />
                                            <span className="text-list">Ideal for personal, office, and educational use</span>
                                        </li>
                                    </ul>
                                    <div className="card-p-pricing__footer">
                                        <button className="button-p">Coming Soon</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Pricing;
